/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppLayout from 'components/Layout/AppLayout';
import landingPage from 'pages/LandingPage';
// Routes
import signinRoute from 'pages/Signin';
import notFoundPageRoute from 'pages/NotFoundPage';
//
import dashboardRoute from 'pages/Dashboard';
import projectsRoute from 'pages/Projects';
import clientsRoute from 'pages/Clients';
import crewRoute from 'pages/Crew';
import settingsRoute from 'pages/Settings';
//
import todosRoute from 'pages/Todos';
//
import PrivateRoute from './PrivateRoute';
//

export default function createRoutes() {
  return (
    <AppLayout>
      <Switch>
        {/* unprotected route */}
        <Route exact path={landingPage.path} render={() => <landingPage.component />} />
        <Route exact path={signinRoute.path} component={(props) => <signinRoute.component {...props} />} />
        {/* Build Route components from routeSettings Protected route */
          [
            dashboardRoute,
            projectsRoute,
            clientsRoute,
            crewRoute,
            settingsRoute,
            todosRoute,
            /* Add More Routes Here */
          ].map((settings, index) => (
            <PrivateRoute key={`Route-${index}`} {...settings} />
          ))
        }
        <Route component={notFoundPageRoute.component} />
      </Switch>
    </AppLayout>
  );
}
