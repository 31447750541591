/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// styles
import { makeStyles } from '@material-ui/core/styles';
// Material Components
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
//
import Grid from '@material-ui/core/Grid';
//
import styles from './BreadCrumbs.styles';
//

const useStyles = makeStyles(styles);

const BreadCrumbs = ({
  auth, title, path,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleClickLink = (e, toPath) => {
    e.preventDefault();
    const targetLocation = {
      pathname: toPath,
      // search: '?query=abc',
      state: { from: location },
    };
    history.push(targetLocation);
  };

  // const theme = useTheme();
  if (auth.isLoaded && auth.isEmpty) {
    return null;
  }
  // Get the location
  const displayTitle = (title) || path;

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* <Paper className={classes.paper}>xs=12 sm=6</Paper> */}
          <Typography variant="h5">
            { displayTitle }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={(e) => handleClickLink(e, '/')} className={classes.link}>
              <HomeIcon className={classes.icon} />
              Home
            </Link>
            <Link
              color="inherit"
              href={path}
              onClick={(e) => handleClickLink(e, path)}
              className={classes.link}
            >
              { displayTitle }
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </div>
  );
};
export default BreadCrumbs;
