/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
// styles
import { makeStyles } from '@material-ui/core/styles';
// Material Components
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import AccountCircle from '@material-ui/icons/AccountCircle';
//
import AppDrawer from 'components/AppDrawer';
import styles from './NavBar.styles';

const useStyles = makeStyles(styles);

const ElevationScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const NavBar = ({
  auth,
  firebase,
  openDrawer,
  setOpenDrawer,
  title,
  rest,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    firebase.logout();
    setAnchorEl(null);
  };

  const profileMenuItems = (
    <div>
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Box fontStyle="normal" fontSize="fontSize" mr={2}>
          {(auth.displayName) ? auth.displayName : auth.email}
        </Box>
      </Box>

      <Divider />
      <Typography component="div" fontStyle="italic">
        <MenuItem onClick={handleProfileMenuClose}><Box fontStyle="normal" fontSize="fontSize" m={0}>Profile</Box></MenuItem>
        <MenuItem onClick={handleProfileMenuClose}><Box fontStyle="normal" fontSize="fontSize" m={0}>My account</Box></MenuItem>
      </Typography>
      <Divider />
      <MenuItem onClick={handleSignOut}><Box fontStyle="normal" fontSize="fontSize" m={0}>Sign Out</Box></MenuItem>
    </div>
  );

  return (
    <div className={classes.root}>
      <ElevationScroll {...rest}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
          })}
        >
          <Toolbar>
            {/* MENU DRAWER ICON */}
            {
              (auth.isLoaded && !auth.isEmpty)
                ? (
                  <Tooltip id="tooltip-drawermenu" title="Menu Drawer">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: openDrawer,
                      })}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                )
                : <div />
            }
            {/* LOGIN/LOGOUT ICON */}
            <div style={{ flex: 0.01 }} />
            <Typography variant="h5" className={classes.title} onClick={() => { history.push('/'); }}>
              {title}
            </Typography>
            <div className={classes.grow} />
            {
              (auth.isLoaded && !auth.isEmpty)
                ? (

                  <div className={classes.sectionDesktop}>
                    <Tooltip id="tooltip-lgout" title={(auth.displayName) ? auth.displayName : auth.email}>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={openProfileMenu}
                      onClose={handleProfileMenuClose}
                    >
                      {profileMenuItems}
                    </Menu>
                  </div>
                )
                : (
                  <div className={classes.sectionDesktop}>
                    <Tooltip id="tooltip-signin" title="Sign in">
                      <IconButton
                        name="signin"
                        aria-label="Sign In"
                        color="inherit"
                        onClick={() => {
                          history.push('/signin');
                        }}
                        rel="noopener"
                      >
                        <LockIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
            }
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.offset} />
      {
        (auth.isLoaded && !auth.isEmpty)
          ? <AppDrawer title={title} open={openDrawer} setOpen={setOpenDrawer} />
          : <div />
      }
    </div>
  );
};

export default NavBar;
