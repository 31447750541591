export default {
  palette: {
    type: 'dark',
    palette: {
      primary: {
        main: '#01579b',
      },
      secondary: {
        main: '#00695c',
      },
    },
    background: {
      // paper: '#fafafa',
      // default: '#fafafa',
    },
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};
