import React from 'react';
import {
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import LoadingSpinner from 'components/LoadingSpinner';
import ContentLayout from 'components/Layout/ContentLayout';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({
  component: Component, title, location,
}) => {
  const auth = useSelector((state) => state.firebase.auth);
  // Authentication still Loading
  if (!isLoaded(auth)) {
    return <LoadingSpinner />;
  }
  // if authenticated
  if (isLoaded(auth) && !isEmpty(auth)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <ContentLayout
        title={title}
        path={location.pathname}
      >
        <Component />
      </ContentLayout>
    );
  }
  // redirect by default
  return (
    <Redirect
      to={{
        pathname: '/signin',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
