import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import NavBar from 'components/NavBar';
import styles from './AppLayout.styles';

const useStyles = makeStyles(styles);

const AppLayout = ({ children }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <div className={classes.container}>
      <NavBar title="JAYREN-Solutions" openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      {/* Child Conent */}
      <Box
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer,
        })}
      >
        <div className={classes.children}>{children}</div>
      </Box>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppLayout;
