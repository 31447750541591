import Loadable from 'react-loadable';
import LoadingSpinner from 'components/LoadingSpinner';

export default {
  title: 'Projects',
  path: '/projects',
  component: Loadable({
    loader: () => import('./components'),
    loading: LoadingSpinner,
  }),
};
