import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import store from './store';
import createRoutes from './routes';
import * as serviceWorker from './serviceWorker';

// const routes = makeRoutes(store)
const routes = createRoutes(store);
//
ReactDOM.render(
  <App store={store} routes={routes} />,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
