import { connect } from 'react-redux';
// import recompose
import { compose } from 'recompose';
// firebase imports
import { firebaseConnect } from 'react-redux-firebase';

// State from Redux (State to Props)
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
});

// enhancers recompose
// This is the enhancer or recompose
export default compose(
  firebaseConnect(),
  // redux connect
  connect(mapStateToProps, null),
  //
);
