/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';

import { useHistory } from 'react-router-dom';
// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import Hidden from '@material-ui/core/Hidden';
// icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import styles from './AppDrawer.styles';
//

const useStyles = makeStyles(styles);

const AppDrawer = ({ title, open, setOpen }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  // menuitem
  const DrawerMenuItem = ({ menuItemTitle, path, children }) => (
    <Tooltip id={`tooltip${menuItemTitle}`} title={menuItemTitle}>
      <ListItem
        button
        onClick={() => {
          history.push(path);
        }}
      >
        <ListItemIcon>
          {' '}
          { children }
          {' '}
        </ListItemIcon>
        <ListItemText primary={menuItemTitle} />
      </ListItem>
    </Tooltip>
  );
  // menu
  const drawerMenu = (
    <div>
      <Divider />
      <List>
        <DrawerMenuItem menuItemTitle="Dashboard" path="/dashboard">
          <DashboardIcon />
        </DrawerMenuItem>
        <DrawerMenuItem menuItemTitle="Projects" path="/projects">
          <BusinessCenterIcon />
        </DrawerMenuItem>
      </List>
      <Divider />
      <List>
        <DrawerMenuItem menuItemTitle="Clients" path="/clients">
          <SupervisedUserCircleIcon />
        </DrawerMenuItem>
        <DrawerMenuItem menuItemTitle="Crew" path="/crew">
          <AssignmentIcon />
        </DrawerMenuItem>
      </List>
      <Divider />
      <List>
        <DrawerMenuItem menuItemTitle="Settings" path="/settings">
          <SettingsIcon />
        </DrawerMenuItem>
      </List>
    </div>
  );
  // header
  const drawerHeader = (
    <Box bgcolor="primary.main" component="span">

      <div className={classes.toolbar}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
    </Box>

  );

  return (

    <div className={classes.root}>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {drawerHeader}
          {drawerMenu}
        </Drawer>
      </Hidden>

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerHeader}
          {drawerMenu}
        </Drawer>
      </Hidden>

    </div>
  );
};
export default AppDrawer;
