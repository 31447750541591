import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { firebaseReducer, actionTypes } from 'react-redux-firebase';
import { firestoreReducer, actionTypes as actionTypesFirestore } from 'redux-firestore';

export default configureStore({
  middleware: [
    ...getDefaultMiddleware({
      // ignore serialisable console error complaint on certain actions from react-redux-firebase
      serializableCheck: {
        ignoredActions: [
          actionTypes.LOGIN,
          actionTypes.LOGIN_ERROR,
          actionTypes.SET_LISTENER,
          actionTypes.UNSET_LISTENER,
          actionTypes.SET_PROFILE,
          actionTypesFirestore.LISTENER_RESPONSE,
          actionTypesFirestore.SET_LISTENER,
          actionTypesFirestore.UNSET_LISTENER,
        ],
      },
    }),
  ],
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  },
});
