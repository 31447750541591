export default (theme) => ({
  root: {
    display: 'flex',
  },
  link: {
    display: 'flex',
  },
  title: {
  },
  paper: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
});
