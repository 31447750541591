export default (theme) => ({
  sitemap: {
    flexGrow: 1,
    padding: theme.spacing(1, 1),
    margin: theme.spacing(1, 0),
  },
  content: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
  },
});
