import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import BreadCrumbs from 'components/BreadCrumbs';
import styles from './ContentLayout.styles';

const useStyles = makeStyles(styles);

const ContentLayout = ({ title, path, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Paper className={classes.sitemap} elevation={0}>
        <BreadCrumbs title={title} path={path} />
      </Paper>
      <Box className={classes.content}>
        <div className={classes.children}>{children}</div>
      </Box>
    </div>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ContentLayout;
