import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import styles from './LoadingSpinner.styles';

const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
const LoadingSpinner = ({ size }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <CircularProgress mode="indeterminate" size={size || 80} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
