/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'dev';

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyDL7fK5ajZKBSr7oO66-DrvdOgUoECZQjk',
  authDomain: 'jayren-react-firebase.firebaseapp.com',
  databaseURL: 'https://jayren-react-firebase.firebaseio.com',
  projectId: 'jayren-react-firebase',
  storageBucket: 'jayren-react-firebase.appspot.com',
  messagingSenderId: '33369276337',
  appId: '1:33369276337:web:f756f61becdb091bd20790',
  measurementId: 'G-02N3BTTTSE',
};

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
};

// ======================================================
// Default Redux + Firebase Config used for all environments
// (for react-redux-firebase & redux-firestore)
// Note: Differs from src/config.js which is environment specific config
// ======================================================
export const rrfConfig = {
  userProfile: 'users', // root that user profiles are written to
  updateProfileOnLogin: false, // enable/disable updating of profile on login
  presence: 'presence', // list currently online users under "presence" path in RTDB
  sessions: null, // Skip storing of sessions
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  // eslint-disable-next-line max-len
  useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
  // profileDecorator: (userData) => ({ email: userData.email }) // customize format of user profile
};

export default {
  env,
  firebase,
  reduxFirebase,
  rrfConfig,
};
